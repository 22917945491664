import { css } from '@linaria/core';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from './Common/hooks/mobx.hooks';
import { joinClassNames } from './Common/utils/css.utils';
import { decodeString, encodeString } from './Common/utils/encode.utils';
import { IS_DEV } from './env';
import AppLogo from './Playground/DesignSystem/components/AppLogo/AppLogo';
import BaseInput from './Playground/DesignSystem/components/BaseInput/BaseInput';
import BaseSpacer from './Playground/DesignSystem/components/BaseSpacer/BaseSpacer';
import { useSyncWithStore } from './Playground/LocalStorageSystem/hooks/useSyncWithStore';

const container = css`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  padding: 20vh 0;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
`

const input = css`
  border: 1px solid white;
  border-radius: 4rem;
  color: white;
`

const appLogo = css`
  max-height: 10rem;
`

type SecurityBlockerProps = {}

const SecurityBlocker: React.FC<SecurityBlockerProps> = props => {

  const s = useStore(() => ({
    form: {
      userEntered: '',
    },
    get passcode() {
      return process.env.REACT_APP_PASSCODE;
    },
    get success() {
      // return s.form.userEntered === "hellothere";
      return (IS_DEV && false) || s.form.userEntered === s.passcode;
    },
  }));

  useSyncWithStore({
    key: 'AppSecurity',
    getter: () => encodeString(s.form.userEntered),
    setter: (v) => s.form.userEntered = decodeString(v),
    validator: (v) => s.success && !!v,
  });

  return <Observer children={() => (
    s.success
      ? <>{props.children}</>
      : <div className={joinClassNames("SecurityBlocker", container)}>
        <AppLogo className={appLogo} />
        <BaseSpacer />
        <BaseInput
          className={input}
          form={s.form}
          field='userEntered'
          type="password"
          placeholder={"Passcode..."}
        />
      </div>
  )} />

}

export default SecurityBlocker;