import { reverseString } from './string.utils';

const key = '=hTa8D3ATc';

export const encodeString = (strToEncode: string) => {
  // reverse
  // btoa
  // add key
  return key.concat(btoa(reverseString(strToEncode)));
}
export const decodeString = (strToDecode: string) => {
  // remove key
  // atob
  // reverse
  return reverseString(atob(strToDecode.replace(key, '')));
}

/**
 * Use when you just want to store the URL.
 *
 * Use the alternate encodeURI if you still want
 * common URI symbol/character meanings.
 */
export const encodeURISafe = (strToEncode: string) => {
  return encodeURIComponent(strToEncode);
}
export const decodeURISafe = (urlSafeEncodedStr: string) => {
  return decodeURIComponent(urlSafeEncodedStr);
}