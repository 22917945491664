import { observable } from 'mobx';
import Pusher from 'pusher-js';

import { PUSHER_CONFIG } from '../../env';
import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

const pusher = new Pusher(PUSHER_CONFIG.key, {
  cluster: PUSHER_CONFIG.cluster,
  forceTLS: true,
})

export type TransceiverController = ReturnType<typeof makeTransceiverController>;

export const makeTransceiverController = () => {
  const c = observable({
    ...makeControllerBase('TRANSCEIVER'),
    newChannel: (channelName: string, eventName: string, callback: (data: any) => void) => {
      const channel = pusher.subscribe(channelName)
      channel.bind(eventName, callback)
    },
    unsubscribe: (channelName: string) => {
      pusher.unsubscribe(channelName);
    },
  })

  c.init = makeRootControllerChildInitFn(
    c,
    async () => {
      // yield setTimeout(() => {}, 1000);
    },
  )

  return c;
};