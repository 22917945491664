import React, { useEffect } from "react";

export function useOnMount(func: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(func, []);
}

export function useOnUnmount(func: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => func(), []);
}
