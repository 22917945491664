import axios from 'axios';
import { flow, observable } from 'mobx';

import { API_URL } from '../../env';
import { ModelName } from '../constants/modelName.enum';
import { AnyObject } from '../types/utilities.types';
import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/v1`,
  // baseURL: "https://6ca7-2a02-8084-6983-f600-8158-b782-a588-7129.eu.ngrok.io",
  headers: {
    "Content-Type": "application/json",
  },
});

export type APIController = ReturnType<typeof makeAPIController>;

export const makeAPIController = () => {
  const c = observable({
    ...makeControllerBase('API'),
    get: async <T extends Object = any>(url: string) => {
      const { data } = await axiosInstance.get<T>(url);
      if (!data) return null;
      return data;
    },
    post: async (url: string, payload: AnyObject, modelName?: ModelName) => {
      const { data } = await axiosInstance.post(url, payload);
      return data;
    },
  })

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function* () {
      // yield setTimeout(() => {}, 1000);
    }),
  )

  return c;
};