/* eslint-disable jsx-a11y/alt-text */
import { css } from '@linaria/core';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useProps, useStore } from '../../../../Common/hooks/mobx.hooks';
import { joinClassNames } from '../../../../Common/utils/css.utils';


const container = css`
`

type BaseImageProps = {
  className?: string,
  src: string,
  style?: React.CSSProperties,
  altText?: string,
}

const BaseImage: React.FC<BaseImageProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get commonAttrs() {
      return {
        className: joinClassNames("BaseImage", container, p.className),
        src: p.src,
        style: p.style,
        alt: p.altText ?? "",
      }
    }
  }));

  return <Observer children={() => (
    <img {...s.commonAttrs} />
  )} />

}

export default BaseImage;