import * as Sentry from '@sentry/react';

import { ENABLE_SENTRY } from '../../env';

export const reportError = (error: Error) => {
  console.error(error);
  if (ENABLE_SENTRY) reportToSentry(error);
}

export const reportToSentry = (error: Error) => {
  Sentry.captureException(error)
}