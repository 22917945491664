import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

import AppContainer from './AppContainer';
import ErrorBoundary from './Common/components/ErrorBoundary/ErrorBoundary';
import { RootContext, RootContextInstance } from './Common/context/context';
import { ENABLE_SENTRY } from './env';

if (ENABLE_SENTRY) {
  Sentry.init({
    dsn: "https://eb44331ad1c843c8aa7d4133bd0c5441@o1247562.ingest.sentry.io/6407520",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RootContext.Provider value={RootContextInstance}>
        <AppContainer />
      </RootContext.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

export { globals } from './styles/global';