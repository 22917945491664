import { Observer } from 'mobx-react-lite';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { useRootController } from './Common/hooks/controller.hooks';
import { messages } from './Common/localization/langs';
import SecurityBlocker from './SecurityBlocker';

type AppContainerProps = {}

const AppContainer: React.FC<AppContainerProps> = props => {

  const ROOT = useRootController();

  return <Observer children={() => (
    <div className="AppContainer">
      {ROOT.ready ?
        <SecurityBlocker>
          <IntlProvider messages={messages['en']} locale="en">
            <BrowserRouter basename='/'>
              <App />
            </BrowserRouter>
          </IntlProvider>
        </SecurityBlocker>
        : <div>Loading...</div>
      }
    </div>
  )} />

}

export default AppContainer;