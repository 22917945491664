import { flow, IReactionDisposer, reaction } from 'mobx';

import { useControllers } from '../../../Common/hooks/controller.hooks';
import { useOnMount } from '../../../Common/hooks/lifecycle.hooks';
import { useProps } from '../../../Common/hooks/mobx.hooks';
import { SHOULD_LOG } from '../../../env';

export const useSyncWithStore = <T extends any = any>(props: {
  key: string,
  validator?: (v: T) => boolean, // store if true.
  getter: () => T,
  setter: (v: T) => void,
}) => {

  const { STORE } = useControllers();
  const p = useProps(props);

  useOnMount(() => {
    let isPropChanged = false;
    let ifObservedPropChangedThenStore = null as null | IReactionDisposer;
    let ifObservedStoreChangedThenAssign = null as null | IReactionDisposer;
    const createObservedStoreReactions = () => {
      ifObservedPropChangedThenStore = reaction(
        p.getter,
        async (v) => {
          if (p.validator?.(v) ?? !!v) {
            isPropChanged = true;
            SHOULD_LOG && console.log('Observed change in prop, setting', p.key);
            await STORE.set(p.key, v);
            isPropChanged = false;
          }
        }
      )
      ifObservedStoreChangedThenAssign = reaction(
        () => STORE.getFromCache(p.key),
        async (v) => {
          if (isPropChanged) return;
          SHOULD_LOG && console.log('Observed change in storage, assigning', p.key);
          if (!!v) {
            p.setter(v);
          }
        }
      )
    }

    const ifExistsThenAssign = flow(function* () {
      const v = yield STORE.get(p.key);
      SHOULD_LOG && console.log('Exists in storage, assigning', p.key);
      if (!!v) {
        p.setter(v);
      }
      createObservedStoreReactions();
    })
    ifExistsThenAssign();

    return () => {
      ifObservedPropChangedThenStore?.();
      ifObservedStoreChangedThenAssign?.();
    }
  })
}