import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';

import NavBar from './Common/components/NavBar/NavBar';
import StandardFooter from './Common/components/StandardFooter/StandardFooter';
import BaseLink from './Playground/DesignSystem/components/BaseLink/BaseLink';
import { routeDef } from './routeDef';

type AppProps = {}

const App: React.FC<AppProps> = props => {
  return (
    <Observer
      children={() => (
        <div className="App">
          {/* nav */}
          {/* the kind that on hover entire nav, expands the nav body. */}
          <NavBar />
          <nav>
            <ul>
              {routeDef.map(c => (
                <li key={c.Label}>
                  <BaseLink to={c.path}>{c.Label}</BaseLink>
                </li>
              ))}
            </ul>
          </nav>

          {/* main */}
          {/* two view nav config with router stack */}
          <main>
            <Switch>
              {routeDef.map(c => (
                c.Component && <Route key={c.Label} path={c.path}>
                  <c.Component />
                </Route>
              ))}
            </Switch>
          </main>

          {/* hotkey replace ctrl + f, shows overlay to search content */}
          {/* content needs to be structured data somewhere, so it can easily be searched. */}

          {/* footer */}
          <StandardFooter />

        </div>
      )}
    />
  );
}

export default App;
