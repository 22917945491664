import { css } from '@linaria/core';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useProps, useStore } from '../../../../Common/hooks/mobx.hooks';
import { AnyObject } from '../../../../Common/types/utilities.types';
import { joinClassNames } from '../../../../Common/utils/css.utils';

const container = css`
  padding: 4px 16px;
`

type BaseInputProps<T extends AnyObject = AnyObject> = {
  type?: React.HTMLInputTypeAttribute,
  form?: T,
  field?: keyof T,
  style?: React.CSSProperties,
  className?: string,
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  defaultValue?: string,
  placeholder?: string,
}

const BaseInput: React.FC<BaseInputProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    _value: null,
    get value() {
      if (p.form && p.field) return p.form[p.field];
      else return s._value;
    },
    set value(v) {
      if (p.form && p.field) p.form[p.field] = v;
      else s._value = v;
    },
    handleChange: action((e: React.ChangeEvent<HTMLInputElement>) => {
      s.value = e.currentTarget.value;
      p.handleChange?.(e);
    }),
    get commonAttrs() {
      return {
        className: joinClassNames("BaseInput", container, p.className),
        type: p.type ?? "text",
        value: p.defaultValue ?? s.value,
        placeholder: p.placeholder,
        onChange: s.handleChange,
        style: p.style,
      }
    }
  }));

  return <Observer children={() => (
    <input
      {...s.commonAttrs}
    />
  )} />

}

export default BaseInput;
