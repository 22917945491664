import { css } from '@linaria/core';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import AppLogo from '../../../Playground/DesignSystem/components/AppLogo/AppLogo';
import { joinClassNames } from '../../utils/css.utils';

const navBar = css`
  // TODO
  // if < desktop,
  // logo at center.
  // collapse into a menu button.

  // if > desktop,
  // logo at left,

  // if > desktop and hover,
  // show hidden stuff.
`

const config = {
}

type NavBarProps = {}

const NavBar: React.FC<NavBarProps> = props => {

  return <Observer children={() => (
    <nav className={joinClassNames("NavBar", navBar)}>
      <AppLogo />
    </nav>
  )} />

}

export default NavBar;