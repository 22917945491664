import { css } from '@linaria/core';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { useProps, useStore } from '../../hooks/mobx.hooks';
import { joinClassNames } from '../../utils/css.utils';

const container = css`
`

type StandardFooterProps = {}

const StandardFooter: React.FC<StandardFooterProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({}));

  return <Observer children={() => (
    <footer className={joinClassNames("StandardFooter", container)}>

    </footer>
  )} />

}

export default StandardFooter;