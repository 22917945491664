import loadable from '@loadable/component';

export const routeDef = [
  {
    path: "/",
    Label: "Home",
    Component: null,
  },
  // {
  //   path: "/joyride",
  //   Label: "Joyride",
  //   Component: loadable(() => import('./Playground/Joyride/JoyrideWrapper')),
  // },
  // {
  //   path: "/loader",
  //   Label: "Loader",
  //   Component: loadable(() => import('./Playground/Loader/LoaderTester')),
  // },
  {
    path: "/imageControls",
    Label: "Image Controls",
    Component: loadable(() => import('./Playground/ImageControlsTester/ImageControlsTester')),
  },
  // {
  //   path: "/activeComponentsCacher",
  //   Label: "Active Components Cacher",
  //   Component: loadable(() => import('./Playground/ActiveComponentsCacher/ActiveComponentsCacherTester')),
  // },
  // {
  //   path: "/presence",
  //   Label: "Presence (WIP)",
  //   Component: loadable(() => import('./Playground/Presence/PresenceTester')),
  // },
  // {
  //   path: "/dnd",
  //   Label: "DnD (WIP)",
  //   Component: loadable(() => import('./Playground/DnDTester/DnDTester')),
  // },
  // {
  //   path: "/timeline",
  //   Label: "Timeline (WIP)",
  //   Component: loadable(() => import('./Playground/TimelineTester/TimelineTester')),
  // },
  // {
  //   path: "/routerStack",
  //   Label: "Router stack (WIP)",
  //   Component: loadable(() => import('./Playground/RouterStackTester/RouterStackTester')),
  // },
  {
    path: "/countryMap",
    Label: "Country Map (WIP)",
    Component: loadable(() => import('./Playground/CountryMap/CountryMap')),
  },
  // {
  //   path: "/encodings",
  //   Label: "Encoding",
  //   Component: loadable(() => import('./Playground/EncodingTester/EncodingTester')),
  // },
  // {
  //   path: "/errorBoundary",
  //   Label: "Error Boundary",
  //   Component: loadable(() => import('./Playground/ErrorBoundaryTester/ErrorBoundaryTester')),
  // },

  {
    path: "/local-storage-system",
    Label: "Local Storage System",
    Component: loadable(() => import('./Playground/LocalStorageSystem/LocalStorageSystem')),
  },

  // TODO Detect internet connection.
  // Allow refresh page / reperform code to fetch data etc.

  // TODO swiper with multiple components in them.

  // TODO+ Mock Components with mocked API data.

  // TODO a reusable function that validates/expects things, and if doesnt exist, throws an error.
  // propExpect(form, [{prop: 'email', 'type': 'string', 'required': true, customValidator: () => boolean}])'}]);
  // throws error if not of expected type, is null, and customValidator is false.

  // TODO GDPR manager.

  // TODO mobile friendly side panels that open if touch across screen right to open, and left to close panel, etc.

  // TODO resize observers

  // TODO

  // TODO Copy to clipboard

  // TODO color themes + playing with color palettes.
  // https://www.youtube.com/watch?v=GyVMoejbGFg

  // TODO global loader like Youtube's

  // TODO breakpoints to check if isPhone, isTablet, isDesktop, etc.

  // TODO search results dropdown


  // animate number going up and down
  {
    path: "/char-animator",
    Label: "Char Animator",
    Component: loadable(() => import('./Playground/CharAnimator/CharAnimator')),
  },


  // TODO design system showcase (with buttons, inputs, dropdowns etc) + theming with genshin character colors
  {
    path: "/design-system",
    Label: "Design System (WIP)",
    Component: loadable(() => import('./Playground/DesignSystem/DesignSystem')),
  },

  // mobile swipe left, right, top and bottom support
  {
    path: "/mobile-swipe-listeners",
    Label: "Mobile Swipe Listeners",
    Component: loadable(() => import('./Playground/MobileSwipesTester/MobileSwipesTester')),
  },

  // occasional actions prompt
  {
    path: "/actions-prompt",
    Label: "Actions Prompt",
    Component: loadable(() => import('./Playground/ActionsPromptTester/ActionsPromptTester')),
  },
]