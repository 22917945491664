import { styled } from '@linaria/react';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { Size } from '../../../../Common/constants/size.enums';
import { useProps } from '../../../../Common/hooks/mobx.hooks';

const Container = styled.span<{ size?: string, inline?: boolean }>`
  width: ${(props) => props.size ?? '1rem'};
  height: ${(props) => props.size ?? '1rem'};
  display: ${(props) => props.inline ? 'inline-block' : 'block'} ;
`

type BaseSpacerProps = {
  size?: Size | string,
  inline?: boolean,
}

const BaseSpacer: React.FC<BaseSpacerProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <Container className="BaseSpacer" {...p} />
  )} />

}

export default BaseSpacer;