export const PUSHER_CONFIG = {
  key: "e048b9cc7c3bbdaa224c",
  cluster: "eu",
}

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEV && process.env.NODE_ENV === 'production';
export const ENABLE_SENTRY = IS_PROD;

const shouldLog = () => {
  if (IS_DEV) return true;
  // TODO has expected hash in localStorage, then true.
  return false;
}
export const SHOULD_LOG = shouldLog();

const getApiURL = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case "www.bryansng.dev":
    case "localhost":
      return "http://localhost:3030";
    default:
      return "http://192.168.0.234:3030";
  }
}
export const API_URL = getApiURL();