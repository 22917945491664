import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

import { useProps, useStore } from '../../../../Common/hooks/mobx.hooks';
import { joinClassNames } from '../../../../Common/utils/css.utils';

type BaseLinkProps = {
  className?: string,
  to?: string,
  href?: string,
  Label?: JSX.Element | string,

  onClick?: (e?: React.MouseEvent<HTMLElement>) => void,

  externalInNewTab?: boolean,

  stopPropagation?: boolean,
  preventDefault?: boolean,
}

const BaseLink: React.FC<BaseLinkProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get isExternalLink() {
      return !!p.href || ["http://", "https://", "mailto:", "tel:", "www."].some(str => p.to?.startsWith(str));
    },
    handleClick: action((e: React.MouseEvent<HTMLElement>) => {
      if (p.preventDefault) e.preventDefault();
      if (p.stopPropagation) e.stopPropagation();
      p.onClick?.();
    }),
    get commonAttrs() {
      return {
        className: joinClassNames('BaseLink', p.className),
        children: p.Label ?? p.children,
        onClick: s.handleClick,

        target: s.isExternalLink && p.externalInNewTab ? '_blank' : undefined,
        rel: s.isExternalLink && p.externalInNewTab ? 'noopener noreferrer' : undefined,
      }
    }
  }));

  return <Observer children={() => {
    switch (true) {
      case s.isExternalLink:
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a {...s.commonAttrs} href={p.href || p.to} />
      case Boolean(p.to):
        return <Link {...s.commonAttrs} to={p.to!} />
      default:
        return <span {...s.commonAttrs} />
    }
  }} />

}

export default BaseLink;