import { observable } from 'mobx';

import { nouns } from '../constants/nouns.constants';
import { positiveAdjectives } from '../constants/positiveAdjectives.constants';
import { getRandomInt } from '../utils/math.utils';
import { capitalize } from '../utils/string.utils';
import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';
import { APIController } from './api.controller';

export type AuthController = ReturnType<typeof makeAuthController>;

export const makeAuthController = () => {
  const c = observable({
    ...makeControllerBase('AUTH'),
    get API(): APIController { return c.ROOT!.children!.API; },

    name: `${capitalize(positiveAdjectives[getRandomInt(0, positiveAdjectives.length)])} ${capitalize(nouns[getRandomInt(0, nouns.length)])}`,
    // get name() {
    //   return `${capitalize(positiveAdjectives[getRandomInt(0, positiveAdjectives.length)])} ${capitalize(nouns[getRandomInt(0, nouns.length)])}`;
    // },
  })

  c.init = makeRootControllerChildInitFn(
    c,
    async () => {
      // yield setTimeout(() => {}, 1000);
    },
  )

  return c;
};