import { action, observable } from 'mobx';

import { makeControllerBase, makeRootControllerChildInitFn } from '../../../Common/controllers/_root.controller';
import { APIController } from '../../../Common/controllers/api.controller';
import { TransceiverController } from '../../../Common/controllers/transceiver.controller';
import { MousePresence } from '../models/makeMousePresence.model';

export type PresenceController = ReturnType<typeof makePresenceController>;

export const makePresenceController = () => {
  const c = observable({
    ...makeControllerBase('PRESENCE'),
    get API(): APIController { return c.ROOT!.children!.API; },
    get TRANSCEIVER(): TransceiverController { return c.ROOT!.children!.TRANSCEIVER; },

    presences: {} as { [key in string]: MousePresence },

    makeMousePresenceChannel: () => {
      c.TRANSCEIVER.newChannel('presence-mouse-presence', 'mouse-presence-created', action((data: MousePresence) => {
        // console.log("Received mouse-presence-created", data);
        c.presences[data.text] = data;
      }));
    },
  })

  c.init = makeRootControllerChildInitFn(
    c,
    async () => {
      c.makeMousePresenceChannel();
      // yield setTimeout(() => {}, 1000);
    },
  )

  return c;
};