import dayjs from 'dayjs';
import { action, observable } from 'mobx';

import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

export type ClockController = ReturnType<typeof makeClockController>;

export const makeClockController = () => {
  const c = observable({
    ...makeControllerBase('CLOCK'),

    _now: dayjs() as dayjs.Dayjs,
    get now(): dayjs.Dayjs { return dayjs(c._now) },
    tick: action(() => {
      c._now = dayjs();
      // console.log('ticking', c._now);
    })
  })

  c.init = makeRootControllerChildInitFn(
    c,
    function () {
      const clockIntervalId = setInterval(() => c.tick(), 1000);
      return () => {
        clearInterval(clockIntervalId);
      }
    },
  )

  return c;
};