import { observable } from 'mobx';

import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

export type UIController = ReturnType<typeof makeUIController>;

export const makeUIController = () => {
  const c = observable({
    ...makeControllerBase('UI'),

    // TODO init overlay, dialog, toast, etc controllers.

    // TODO media queries, e.g. isphone, etc
  })

  c.init = makeRootControllerChildInitFn(
    c,
    function () {
    },
  )

  return c;
};