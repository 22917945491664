import { action, flow, observable } from 'mobx';

import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

export type DebounceController = ReturnType<typeof makeDebounceController>;

export const makeDebounceController = () => {
  const c = observable({
    ...makeControllerBase('DEBOUNCE'),

    idsToTimeoutIdsMap: {} as Record<string, NodeJS.Timeout>,
    debounce: (fn: (...arg: any) => void, id: string, delayMs?: number) => {
      var timerId: NodeJS.Timeout;
      return (...args: any) => {
        if (id in c.idsToTimeoutIdsMap) {
          timerId = c.idsToTimeoutIdsMap[id];
        }
        c.addTimeout(timerId, fn, args, id, delayMs);
      }
    },
    throttle: (fn: (...arg: any) => void, id: string, delayMs?: number) => {
      var timerId: NodeJS.Timeout;
      return (...args: any) => {
        if (id in c.idsToTimeoutIdsMap) return;
        c.addTimeout(timerId, fn, args, id, delayMs);
      }
    },
    addTimeout: action((timerId: NodeJS.Timeout, fn: (...arg: any) => void, args: any, id: string, delayMs?: number) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        fn.apply(undefined, args);
        delete c.idsToTimeoutIdsMap[id];
      }, delayMs ?? 500)
      c.idsToTimeoutIdsMap[id] = timerId;
    }),
  })

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function* () {
      // yield setTimeout(() => {}, 1000);
    }),
  )

  return c;
};