import { css } from '@linaria/core';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import imgLogo from '../../../../assets/images/logo-bs.png';
import { useProps } from '../../../../Common/hooks/mobx.hooks';
import { joinClassNames } from '../../../../Common/utils/css.utils';
import BaseImage from '../BaseImage/BaseImage';

const img = css`
  height: 100%;
  max-height: 3rem;
`

type AppLogoProps = {
  className?: string,
}

const AppLogo: React.FC<AppLogoProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <div className="AppLogo">
      <BaseImage
        className={joinClassNames(img, p.className)}
        src={imgLogo}
        altText="App Logo"
      />
    </div>
  )} />

}

export default AppLogo;
