import { flow, observable } from 'mobx';

import { IS_DEV } from '../../env';
import { makeStoreController, StoreController } from '../../Playground/LocalStorageSystem/controllers/store.controller';
import { makePresenceController, PresenceController } from '../../Playground/Presence/controllers/presence.controller';
import { AnyObject } from '../types/utilities.types';
import { APIController, makeAPIController } from './api.controller';
import { AuthController, makeAuthController } from './auth.controller';
import { ClockController, makeClockController } from './clock.controller';
import { DebounceController, makeDebounceController } from './debounce.controller';
import { makeTransceiverController, TransceiverController } from './transceiver.controller';
import { makeUIController, UIController } from './ui.controller';

export type Controller<
  T extends AnyObject = {},
  ChildrenType extends DefaultControllerChildrenType = {}
  > = T & ControllerBase<ChildrenType>;

export type DefaultControllerChildrenType = { [key: string]: Controller };

export type ControllerBase<ChildrenType extends DefaultControllerChildrenType = {}> = {
  name: string,
  ready: boolean,
  ROOT?: RootController,
  init: ControllerInitFn,
  children: ChildrenType,
}

export type ControllerInitFn = (ROOT?: RootController) => Promise<true>;

export type RootController = ControllerBase<RootControllerChildren>;

export type RootControllerChildren = {
  API: APIController,
  AUTH: AuthController,
  CLOCK: ClockController,
  DEBOUNCE: DebounceController,
  TRANSCEIVER: TransceiverController,
  UI: UIController,
  PRESENCE: PresenceController,
  STORE: StoreController,
}

export const makePromiseTrue = () => {
  return new Promise<true>((resolve, reject) => { resolve(true) });
}

export const makeControllerBase = (name: string) => ({
  ROOT: undefined as RootController | undefined,
  name,
  ready: false,
  init: (ROOT?: RootController) => makePromiseTrue(),
  children: {},
})

export const makeRootController = () => {

  const c = observable({
    name: 'ROOT',
    get ROOT() { return c; },
    ready: false,
    init: () => new Promise<true>(flow(function* (resolve, reject) {

      for (const controller of Object.values(c.children)) {
        yield controller.init(c);
      }
      IS_DEV && console.log("init root controller");

      IS_DEV && Reflect.set(window, 'ROOT', c);
      for (const [controllerName, controller] of Object.entries(c.children)) {
        IS_DEV && Reflect.set(window, controllerName, controller);
      }
      c.ready = true;
      resolve(true);
    })),
    children: {
      API: makeAPIController(),
      AUTH: makeAuthController(),
      CLOCK: makeClockController(),
      DEBOUNCE: makeDebounceController(),
      TRANSCEIVER: makeTransceiverController(),
      UI: makeUIController(),
      PRESENCE: makePresenceController(),
      STORE: makeStoreController(),
    } as RootControllerChildren,
  })

  return c;
}

export const makeRootControllerChildInitFn = (
  controller: AnyObject,
  fn: Function,
) => {
  return (ROOT?: RootController) => new Promise<true>(flow(function* (resolve, reject) {
    try {
      controller.ROOT = ROOT;
      if (fn) {
        yield fn();
      }
      controller.ready = true;
      resolve(true);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  }))
}