import { Drivers, Storage } from '@ionic/storage';
import { action, flow, observable } from 'mobx';

import { makeControllerBase, makeRootControllerChildInitFn } from '../../../Common/controllers/_root.controller';

export type StoreController = ReturnType<typeof makeStoreController>;

export const makeStoreController = () => {
  const c = observable({
    ...makeControllerBase('STORE'),

    storage: null as Storage | null, // persistent storage.
    memory: {} as { [k in string]: any },

    get: async (key: string) => {
      if (key in c.memory) return c.getFromCache(key);
      return await c.storage?.get(key);
    },
    set: async (key: string, value: any) => {
      c.setInCache(key, value);
      return await c.storage?.set(key, value);
    },
    remove: async (key: string) => {
      c.removeFromCache(key);
      return await c.storage?.remove(key);
    },
    clearAll: async () => {
      c.clearAllCache();
      return await c.storage?.clear();
    },

    get getFromCache() {
      return (key: string) => c.memory[key];
    },
    setInCache: action((key: string, value: any) => {
      c.memory[key] = value;
    }),
    removeFromCache: (key: string) => {
      return delete c.memory[key];
    },
    clearAllCache: () => {
      return c.memory = {};
    },
  })

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function* () {
      c.storage = yield new Storage({
        name: 'PlaygroundAppStorage',
        storeName: 'PlaygroundApp',
        version: 1,
        driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB],
      }).create();
    }),
  )

  return c;
};